import React, { useState } from 'react';
import UserService from '../../../../../Services/user_service/UserService';
import Constants from "../../../../Constants";
import './RenewalCompany.css';

const Modal = ({ message, onClose }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <p style={{ color: 'green' }}>{message}</p> {/* Apply green color to the message */}
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

const RenewalCompany = () => {
    const [companyName, setCompanyName] = useState('');
    const [subscriptionDuration, setSubscriptionDuration] = useState('');
    const [loadingBar, setLoadingBar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const displayErrorMessage = (message) => {
        setErrorMessage(message);
        setTimeout(() => setErrorMessage(''), Constants.SET_TIME_OUT_FOR_ERROR_MSG || 3000);
    };

    const handleUpdateSubscription = async () => {
        if (!companyName.trim() || !subscriptionDuration.trim()) {
            displayErrorMessage('Please fill in both company name and subscription duration.');
            return;
        }

        // Check if subscriptionDuration is a valid number and within the range
        const duration = parseInt(subscriptionDuration, 10);
        if (isNaN(duration) || duration < 90 || duration > 1096) {
            displayErrorMessage('Please enter a valid subscription duration between 90 and 1096 days.');
            return;
        }

        setLoadingBar(true);
        setErrorMessage('');

        try {
            // Send request to update subscription on the backend
            const response = await UserService.updateSuperAdminSubscription(companyName, subscriptionDuration);

            if (response) {
                // The backend now sends back the updated expiration date
                const formattedDate = response;  // Assuming response is in YYYY-MM-DD format

                setModalMessage(`SuperAdmin subscription for ${companyName} updated. Expiration date: ${formattedDate}`);
                setIsModalOpen(true); // Open modal to show message
            } else {
                displayErrorMessage('Failed to update SuperAdmin subscription.');
            }
        } catch (error) {
            console.error(error);
            displayErrorMessage('Error while updating subscription.');
        } finally {
            setLoadingBar(false);
        }
    };

    return (
        <div>
            <h1>Company Users Renewal</h1>

            {/* Error Message */}
            {errorMessage && <div className="error-message" style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div>}

            {/* Inputs and Activate Button in One Line */}
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                {/* Company Name Input */}
                <div style={{ marginRight: '20px' }}>
                    <label htmlFor="companyName" style={{ marginRight: '10px' }}>Enter Company Name:</label>
                    <input
                        type="text"
                        id="companyName"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        placeholder="Enter company name"
                        aria-label="Enter company name"
                        style={{ padding: '5px', fontSize: '14px', borderRadius: '5px', border: '1px solid #ccc' }}
                    />
                </div>

                {/* Subscription Duration Input */}
                <div style={{ marginRight: '20px' }}>
                    <label htmlFor="subscriptionDuration" style={{ marginRight: '10px' }}>Subscription Duration (Days):</label>
                    <input
                        type="text"
                        id="subscriptionDuration"
                        value={subscriptionDuration}
                        onChange={(e) => setSubscriptionDuration(e.target.value)}
                        placeholder="Enter duration in days"
                        aria-label="Subscription Duration"
                        style={{ padding: '5px', fontSize: '14px', borderRadius: '5px', border: '1px solid #ccc' }}
                    />
                </div>

                {/* Activate Button */}
                <button
                    onClick={handleUpdateSubscription}
                    style={{
                        padding: '5px 10px',
                        fontSize: '14px',
                        borderRadius: '5px',
                        backgroundColor: '#28a745',
                        color: '#fff',
                        border: 'none',
                        cursor: 'pointer'
                    }}
                >
                    Activate
                </button>
            </div>

            {/* Loading Indicator */}
            {loadingBar && <p>Loading...</p>}

            {/* Modal Component */}
            {isModalOpen && (
                <Modal
                    message={modalMessage}
                    onClose={() => {
                        setIsModalOpen(false);
                        setModalMessage('');
                    }}
                />
            )}
        </div>
    );
};

export default RenewalCompany;
